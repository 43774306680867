<template>
    <div class="packageform">
        <div class="cb-package-form">
            <div id="citybreak_accommodation_ferry_package_searchform_widget" :class="{ loading: loading }"></div>
            <BaseSpinner color="dark" v-if="loading" />
        </div>
        <div class="usp" v-if="!loading">
            <div class="usp__container" v-for="(row, i) in tab.rows" :key="i">
                <SvgIcon icon="checkmark" class="check" />
                <span v-html="row"> </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import i18n from "@/config/i18n";
import { ref } from "vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { QuickformTab } from "@/models/QuickformTab";

interface Props {
    tab: QuickformTab;
}

const props = defineProps<Props>();

const getLocaleUrl = () => {
    return i18n.global.locale.value;
};

const loading = ref(true);

(function () {
    const widget = document.createElement("script");
    widget.type = "text/javascript";
    widget.async = true;
    widget.src = `https://www2.destinationgotland.se/${getLocaleUrl()}/accommodationferrypackagewidget/searchform?cbisProductId=${
        props.tab.citybreakId
    }`;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(widget, s);
    widget.onload = () => {
        setTimeout(() => {
            loading.value = false;
        }, 600);
    };
})();
</script>

<style lang="scss">
@import "~/styles/cb-package-form.scss";

.packageform {
    @include media-breakpoint-up(lg) {
        display: flex;
        gap: 3rem;
        justify-content: space-between;
    }
}

.cb-form-subset > div {
    gap: 60px !important;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
        gap: 5px !important;
    }

    .cb-form-field {
        .cb-form-label {
            left: 15px !important;
        }
    }
}

.cb-form-subset-label {
    display: none !important;
}

.cb--route {
    @include media-breakpoint-up(sm) {
        .cb--first::after {
            content: url("../../../assets/icons/booking/arrows-round-trip.svg");
            position: absolute;
            width: 40px;
            right: -50px;
            top: 13px;
        }
    }
}

.cb-package-form {
    min-height: 42px;
    position: relative;

    @include media-breakpoint-up(lg) {
        min-width: 65% !important;
    }

    .loading {
        height: 42px;
        overflow: hidden;
        opacity: 0;
    }

    .cb--search {
        .cb-form-field {
            @include media-breakpoint-down(sm) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            > div {
                @include media-breakpoint-down(sm) {
                    width: 180px;
                }

                a {
                    border-width: 0px;
                }
            }
        }
    }
}

#cb-ui-datepicker-div.cb-ui-datepicker {
    .cb-header {
        @include media-breakpoint-down(sm) {
            display: block !important;
            position: absolute;
            top: 330px;
            box-shadow: none;
            text-align: center;
        }
        button {
            background-color: $c-color-secondary !important;
            color: $c-white-100;
            border: 1px solid $c-color-secondary;
            border-radius: 30px;
            line-height: 3;
            cursor: pointer;
            padding: 0 50px;
            font-size: 1rem;
        }
        i {
            display: none;
        }
    }
}

.usp {
    margin-top: 10px;
    margin-right: auto;

    @include media-breakpoint-down(md) {
        display: none;
    }

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__container {
        display: flex;

        .check {
            background-color: $c-color-primary;
            color: $c-white;
            width: 1.5rem;
            height: 1.5rem;
            padding: 5px;
            border-radius: 50%;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }
}
</style>
