<template>
    <div class="quickform">
        <div class="quickform__tabs">
            <ul>
                <li class="tab" v-for="tab in tabs" :key="tab.code" :class="{ active: currentTab?.code === tab.code }">
                    <a v-if="tab.component" @click="currentTab = tab">
                        {{ tab.label }}
                        <span v-if="tab.badge" class="discount">
                            <span>{{ tab.badge }}</span>
                        </span>
                    </a>
                    <a v-else :href="tab.link" id="dg-quickform-external">
                        {{ tab.label }}
                        <span v-if="tab.badge" class="discount">
                            <span>{{ tab.badge }}</span>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="quickform__content">
            <KeepAlive>
                <component :is="currentTab?.component" class="tab" :tab="currentTab"></component>
            </KeepAlive>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { QuickformTab } from "@/models/QuickformTab";
import { shallowRef } from "vue";

interface Props {
    tabs: QuickformTab[];
    activeTabIndex?: number;
}

const props = defineProps<Props>();
const currentTab = shallowRef(props.tabs[props.activeTabIndex ?? 0]);
</script>

<style lang="scss" scoped>
.quickform {
    margin: 0 auto;
    width: 100%;
    max-width: 1260px;

    &__tabs {
        ul {
            display: inline-flex;

            .tab {
                position: relative;
                min-width: 120px;
                cursor: pointer;

                @include media-breakpoint-up(sm) {
                    min-width: 210px;
                }

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 12px 15px 0;
                    height: 35px;
                    background-color: $c-color-primary;
                    color: $c-white-100;
                    border-radius: $br-large;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    @include font(p);
                    font-size: $fs-14;
                    line-height: 1;
                    font-weight: $fw-medium;
                    text-transform: uppercase;
                    text-decoration: none;
                    white-space: nowrap;

                    @include media-breakpoint-up(sm) {
                        padding: 12px 30px 0;
                        font-size: $fs-16;
                    }
                }

                &.active {
                    a {
                        background-color: $c-white-100;
                        color: $c-black-100;
                        font-weight: $fw-bold;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 100%;
                            left: 30px;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-top: 8px solid $c-blue-200;

                            @include media-breakpoint-up(sm) {
                                left: 45px;
                            }
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 4px;
                            background-color: $c-blue-200;
                        }
                    }
                }

                &:not(.active) {
                    a {
                        &:hover {
                            background-color: darken($c-color-primary, 5%);
                        }
                    }
                }
            }
        }
    }

    .discount {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: calc(100% - 10px);
        padding: 0 12px;
        height: 24px;
        background: $c-color-secondary;
        color: $c-white;
        border-radius: 12px;
        white-space: nowrap;
        transform: translateY(-50%);

        span {
            display: block;
            margin-bottom: -2px;
            font-size: $fs-14;
            line-height: 1;
        }
    }

    &__content {
        display: inline-block;
        width: 100%;
        padding: 25px 15px 30px;
        background-color: $c-white-100;
        border-radius: $br-dialog;
        border-top-left-radius: 0;

        @include media-breakpoint-up(sm) {
            padding: 20px 30px;
        }

        .form {
            width: 100%;
        }
    }
}
</style>
