<template>
    <Widget :activeTabIndex="tabIndex" :tabs="tabs"></Widget>
</template>

<script lang="ts" setup>
import Widget from "@/components/widgets/Widget.vue";
import { QuickformTab } from "@/models/QuickformTab";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import AccommodationForm from "./AccommodationForm.vue";
import Ferry from "./FerryForm.vue";
import PackageForm from "./PackageForm.vue";

const config = reactive({
    ferryStartpageUrl: `${document.getElementById("quickform")?.dataset.linkFerry}`,
    externalText: `${document.getElementById("quickform")?.dataset.externalText ?? ""}`,
    externalLink: `${document.getElementById("quickform")?.dataset.externalLink ?? ""}`,
    externalDiscount: `${document.getElementById("quickform")?.dataset.externalDiscount ?? ""}`,
    showFerry: !!(document.getElementById("quickform")?.dataset.showFerry ?? true),
    showAccommodation: !!document.getElementById("quickform")?.dataset.showAccommodation,
    showPackage: !!document.getElementById("quickform")?.dataset.showPackage,
    activeTabIndex: +`${document.getElementById("quickform")?.dataset.activeTabIndex ?? ""}`,
    rowOne: `${document.getElementById("quickform")?.dataset.rowOne ?? ""}`,
    rowTwo: `${document.getElementById("quickform")?.dataset.rowTwo ?? ""}`,
    rowThree: `${document.getElementById("quickform")?.dataset.rowThree ?? ""}`,
    citybreakId: `${document.getElementById("quickform")?.dataset.citybreakId ?? ""}`,
});

const tabs: QuickformTab[] = [];
const { t } = useI18n();

const tabIndex = computed(() => {
    return tabs.length - 1 < config.activeTabIndex ? 0 : config.activeTabIndex;
});

if (config.showFerry) {
    tabs.push({
        component: Ferry,
        code: "Ferry",
        label: t("quickform.tabs.ferry"),
    });
}

if (config.showAccommodation || process.env.NODE_ENV !== "production") {
    tabs.push({
        component: AccommodationForm,
        code: "AccommodationForm",
        label: t("quickform.tabs.accommodation"),
    });
}

if (config.showPackage || process.env.NODE_ENV !== "production") {
    tabs.push({
        component: PackageForm,
        code: "PackageForm",
        label: t("quickform.tabs.package"),
        badge: config.externalDiscount,
        rows: [config.rowOne, config.rowTwo, config.rowThree].filter((r) => !!r),
        citybreakId: config.citybreakId,
    });
}

if (config.externalLink) {
    tabs.push({
        code: "Accommodation",
        link: config.externalLink,
        label: config.externalText,
        badge: config.externalDiscount,
    });
}
</script>

<style lang="scss" scoped></style>
