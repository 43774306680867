<template>
    <DatePicker
        v-model="state.dateRange"
        is24hr
        :locale="{ id: $i18n.locale, masks: { weekdays: 'WW' } }"
        trim-weeks
        is-range
        :minDate="new Date()"
        :popover="{ visibility: 'click' }"
        :columns="$screens({ default: 1, lg: 2 })"
        :select-attribute="rangeHighlights"
        :drag-attribute="rangeHighlights"
        show-iso-weeknumbers
        :step="1"
        class="datepicker-container"
    >
        <template #default="{ inputValue, inputEvents }">
            <div class="date-range">
                <BaseInput
                    :hasError="hasError"
                    v-model="inputValue.start"
                    v-on="inputEvents.start"
                    :label="startDateLabel"
                    icon="booking/calendar"
                />
                <BaseInput :hasError="hasError" v-model="inputValue.end" v-on="inputEvents.end" :label="endDateLabel" icon="booking/calendar" />
            </div>
        </template>
    </DatePicker>
</template>

<script setup lang="ts">
import { DatePicker } from "v-calendar";
import { reactive } from "vue";
import BaseInput from "./BaseInput.vue";
import { rangeHighlights } from "@/config/calendarAttributes";

interface Props {
    hasError?: boolean;
    startDateLabel: string;
    endDateLabel: string;
}

defineProps<Props>();

const state = reactive({
    dateRange: { start: undefined, end: undefined },
});
</script>

<style lang="scss" scoped>
.date-range {
    display: flex;
    flex-flow: row wrap;
    gap: $space-sm;

    > * {
        flex: 1;
        min-width: 200px;
    }
}

:deep(.vc-pane) {
    min-width: 320px;
}

:deep(.vc-weeks) {
    padding: $space-sm;
}

:deep(.vc-arrows-container) {
    padding: 0;
}

.datepicker-container {
    position: relative;

    :deep(.vc-popover-content-wrapper) {
        width: 100%;

        .vc-popover-content {
            &.direction-bottom {
                margin-top: 3px;
            }
        }

        .vc-container {
            width: 100%;
        }

        .vc-popover-caret {
            display: none;
        }
    }
}
</style>
