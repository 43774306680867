<template>
    <div>
        <form class="form" method="GET" ref="quickform" :action="ferryStartpageUrl" @submit.prevent="submitQuickform" id="dg-quickform-ferry">
            <fieldset>
                <div class="form__group">
                    <div class="row">
                        <div class="col--12 col-lg--7 col-xl--6 quickform__group">
                            <div class="quickform__dropdown">
                                <BaseDropdown
                                    :options="routes"
                                    :placeholder="$t('quickform.chooseOutward')"
                                    :hasError="v$.outwardRoute.$error"
                                    v-model="v$.outwardRoute.$model"
                                    name="outwardRoute"
                                />
                            </div>
                            <template v-if="tripValue === 'rt'">
                                <SvgIcon :icon="'booking/arrows-round-trip'" class="icon" />
                                <div class="quickform__dropdown">
                                    <BaseDropdown
                                        :options="filteredRoutes"
                                        :placeholder="$t('quickform.chooseReturn')"
                                        :hasError="v$.returnRoute.$error"
                                        v-model="v$.returnRoute.$model"
                                        name="returnRoute"
                                    />
                                </div>
                            </template>
                        </div>
                        <div class="col--12 col-lg--5 col-xl--6 quickform__group">
                            <div class="radio-checkbox">
                                <div class="radio-container">
                                    <BaseToggleSwitch
                                        id="tripType"
                                        v-model="toggleTrip"
                                        :label="$t('quickform.tripType.return')"
                                        name="isReturn"
                                    />
                                </div>
                            </div>
                            <div class="button-container">
                                <BaseButton class="btn--primary">
                                    <span>{{ $t("quickform.search") }}</span>
                                </BaseButton>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import BaseToggleSwitch from "@/components/base/BaseToggleSwitch.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import useApi from "@/composables/useApi";
import useRuntimeConfig from "@/composables/useRuntimeConfig";
import { departureRoutes, getReturnRoutes, getReverseRoute } from "@/config/routes";
import { required } from "@/helpers/validators";
import { OptionItem } from "@/models/OptionItem";
import { LocaleString } from "@/models/RuntimeConfig";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import { computed, nextTick, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const quickform = ref();
const ferryStartpageUrl = ref("");
const externalLink = ref("#");
const externalText = ref("Boende");
const externalDiscount = ref("");

const { get } = useApi({ displayErrors: false, useBaseUrl: false });
const { t, locale } = useI18n();

const routes = ref<OptionItem[]>(
    Array.from(Object.values(departureRoutes)).map((value) => {
        return {
            value,
            label: t(`routes.${value}`),
        };
    })
);
// Get the routes from api if possible
get<string[]>("/GetVisibleRoutes", "")
    .then((resp) => {
        routes.value = resp.data.map((r) => ({
            label: t(`routes.${r}`),
            value: r,
        }));
    })
    .catch((err) => console.warn(err));

const toggleTrip = ref(true);

const tripValue = computed(() => (toggleTrip.value ? "rt" : "st"));

const submitQuickform = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    nextTick(() => {
        quickform.value.submit();
    });
};

const state = reactive({
    outwardRoute: "",
    returnRoute: "",
});

const rules = {
    outwardRoute: { required },
    returnRoute: {
        required: requiredIf(function () {
            return tripValue.value === "rt";
        }),
    },
};

ferryStartpageUrl.value = `${document.getElementById("quickform")?.dataset.linkFerry}`;
externalText.value = `${document.getElementById("quickform")?.dataset.externalText ?? "Boende"}`;
externalLink.value = `${document.getElementById("quickform")?.dataset.externalLink ?? "#"}`;
externalDiscount.value = `${document.getElementById("quickform")?.dataset.externalDiscount ?? ""}`;

const { getRuntimeConfig } = useRuntimeConfig();

getRuntimeConfig().then((x) => {
    ferryStartpageUrl.value = (x.booking_path as LocaleString)[locale.value as keyof LocaleString];
});

const filteredRoutes = computed(() => {
    return getReturnRoutes(state.outwardRoute, routes.value);
});

watch(filteredRoutes, () => {
    if (state.outwardRoute) {
        state.returnRoute = getReverseRoute(state.outwardRoute);
    }
});

const v$ = useVuelidate(rules, state);
</script>

<style lang="scss" scoped>
.quickform__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
        align-items: flex-end;
    }

    .quickform__dropdown {
        width: 100%;

        @include media-breakpoint-up(sm) {
            flex: 0 1 100%;
        }

        + .quickform__dropdown {
            margin-top: 10px;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }
    }

    .icon {
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        padding: 10px;
        width: 80px;
        height: 42px;
    }

    .radio-checkbox {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            min-height: 42px;
        }
    }

    .radio-container,
    .checkbox-container {
        @include media-breakpoint-only(xs) {
            :deep(.checkbox) > input {
                + label {
                    padding-left: calc(var(--checkbox-size) + 25px);

                    &::before,
                    &::after {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .button-container {
        margin-top: 30px;

        @include media-breakpoint-up(sm) {
            margin-top: 20px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 12px;
        }

        :deep(.btn) {
            width: 160px;
        }
    }

    + .quickform__group {
        flex-direction: column;
        margin-top: 12px;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin-top: 0;
        }

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            align-items: center;
        }
    }
}
</style>
