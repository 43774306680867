interface useUtilites {
	range: (start: number, end: number) => number[];
	repeat: <T>(count: number, value: T) => T[];
}

export const range = (start: number, end: number): number[] => {
	if(isNaN(start) || isNaN(end)) {
		return [];
	}
	return [...Array(end)].map((_, i) => start + i);
}

export const repeat = <T>(count: number, value: T): T[] => {
	if(isNaN(count)) {
		return [];
	}

	return [...Array(count)].map(() => value);
}


export const useUtilities = (): useUtilites => {
	return {
		range,
		repeat
	}
}