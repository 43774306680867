<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="form-container">
                <BaseDateRangePicker
                    :startDateLabel="$t('quickform.accommodation.form.checkin')"
                    :endDateLabel="$t('quickform.accommodation.form.checkout')"
                    :hasError="v$.dateRange.$error"
                    v-model="state.dateRange"
                ></BaseDateRangePicker>
                <div class="form-container__inputs">
                    <BaseDropdown
                        :hasError="v$.accommodationType.$error"
                        v-model="v$.accommodationType.$model"
                        :label="$t('quickform.accommodation.type')"
                        :options="accommodationOptions"
                    ></BaseDropdown>
                    <GuestSelect v-model="v$.rooms.$model" :allowMultipleRooms="allowMultipleRooms"></GuestSelect>
                </div>
                <div class="form-container__campaign">
                    <a href="#" @click.prevent="showCampaignInput = !showCampaignInput">
                        {{ $t("quickform.accommodation.campaign") }}
                    </a>
                    <BaseInput
                        v-model.trim="state.campaignCode"
                        v-if="showCampaignInput"
                        class="form-container__campaign-input"
                        :label="$t('quickform.accommodation.campaignField')"
                    ></BaseInput>
                </div>
            </div>
            <BaseButton class="form__button">
                {{ $t("quickform.accommodation.searchButton") }}
            </BaseButton>
        </form>
    </div>
</template>

<script setup lang="ts">
import GuestSelect from "@/components/accommodation/GuestSelect.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDateRangePicker from "@/components/base/BaseDateRangePicker.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import { accommodationConfig } from "@/config/accommodationTypes";
import { Room } from "@/models/front/rooms/Room";
import useVuelidate from "@vuelidate/core";
import { format } from "date-fns";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

const accommodationOptions = accommodationConfig;
const { locale } = useI18n();

const showCampaignInput = ref(false);

const state = reactive({
    dateRange: { start: undefined, end: undefined },
    accommodationType: 0,
    rooms: [new Room(1, [])],
    campaignCode: "",
});

const rules = {
    dateRange: {
        v: (val: { start: Date; end: Date }) => {
            return val.start && val.end;
        },
    },
    accommodationType: {},
    rooms: {},
};

const allowMultipleRooms = computed(() => accommodationOptions.find((o) => o.value === state.accommodationType)?.multipleRooms);

const v$ = useVuelidate(rules, state);

const submitForm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    if (state.dateRange.start && state.dateRange.end) {
        const start = format(new Date(state.dateRange.start), "yyyy-MM-dd");
        const end = format(new Date(state.dateRange.end), "yyyy-MM-dd");

        const roomQueries = state.rooms.map((r, index) => {
            return `gc-${index}-adults=${r.adults}&gc-${index}-children=${r.childrenAges.length}&gc-${index}-age=${r.childrenAges.map(
                (a) => a
            )}`;
        });

        const queries = `category=${state.accommodationType}&rooms=${state.rooms.length}&start=${start}&end=${end}&${roomQueries}&promocode=${state.campaignCode}`;

        window.location.href = `https://book.destinationgotland.se/${locale.value}/stays?${queries}`;
    }
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .form {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 65%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            &__inputs {
                display: flex;
                flex-flow: row wrap;
                gap: 10px;

                > * {
                    flex: 1;
                    min-width: 200px;
                }
            }

            &__campaign {
                a {
                    text-decoration: underline;
                    color: $c-black-100;
                    font-size: 1rem;
                }

                &-input {
                    width: 220px;
                    padding-top: $space-xs;
                }
            }
        }

        &__button {
            width: 170px;
        }
    }
}
</style>
