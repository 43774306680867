<template>
    <Dropdown>
        <template #trigger="{ toggle, isOpen }">
            <BaseButton class="btn--input active" :class="{ isOpen: isOpen }" type="button" icon="chevron/chevron-down" @click="toggle">
                <label>{{ $t("quickform.accommodation.numberOfGuests") }}</label>
                <span>
                    <span v-if="allowMultipleRooms">{{ rooms.length }} {{ $t("quickform.accommodation.room") }}, &nbsp;</span>
                    <span> {{ guestCount }} {{ $t("quickform.accommodation.guests") }}</span>
                </span>
            </BaseButton>
        </template>
        <div class="guest-select">
            <GuestSelectRow
                v-for="(room, index) in rooms"
                :key="room.id"
                :id="room.id"
                :index="index + 1"
                :deletable="rooms.length > 1"
                v-model:room="rooms[index]"
                @onRemove="removeRoom"
                :allowMultipleRooms="allowMultipleRooms"
            />
            <BaseButton v-if="allowMultipleRooms" class="btn--transparent" type="button" @click="addRoom">
                + {{ $t("quickform.accommodation.addRoom") }}
            </BaseButton>
        </div>
    </Dropdown>
</template>

<script setup lang="ts">
import { Room } from "@/models/front/rooms/Room";
import { computed, watch } from "vue";
import BaseButton from "../base/BaseButton.vue";
import Dropdown from "../Dropdown.vue";
import GuestSelectRow from "./GuestSelectRow.vue";

interface Props {
    allowMultipleRooms?: boolean;
    modelValue: Room[];
}

const props = withDefaults(defineProps<Props>(), {
    allowMultipleRooms: false,
});

const emit = defineEmits(["update:modelValue"]);

const rooms = computed({
    get() {
        return props.modelValue;
    },
    set(value: Room[]) {
        emit("update:modelValue", value);
    },
});

watch(
    () => props.allowMultipleRooms,
    () => {
        rooms.value = [new Room(1, [])];
    }
);

const guestCount = computed(() => {
    return rooms.value.reduce((a, b) => a + (b.adults ?? 0) + (b.childrenAges ?? []).length, 0);
});

const addRoom = () => {
    rooms.value.push(new Room(1, []));
};

const removeRoom = (id: string) => {
    rooms.value = rooms.value.filter((r) => r.id !== id);
};
</script>

<style lang="scss" scoped>
@import "~/styles/forms.scss";
.guest-select {
    padding: $space-md;

    .btn--transparent {
        padding: 0;
    }
}

.isOpen {
    :deep(.icon) {
        transform: translateY(-50%) rotate(180deg);
    }
}
</style>
