import { createId } from "@/helpers/idHelper";


export class Room {
    id: string;
    adults: number;
    childrenAges?: number[]

    constructor(adults: number, children: number[]) {
        this.id = createId();
        this.adults = adults
        this.childrenAges = children
    }
}