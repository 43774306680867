import { createApp } from 'vue';
import i18n from '~/config/i18n';
import { pinia } from "~/store";
import SetupCalendar from 'v-calendar';
import App from './App.vue';
import { VueQueryPlugin } from 'vue-query';

const app = createApp(App)
app.use(SetupCalendar, {})
app.use(pinia)
app.use(i18n);
app.use(VueQueryPlugin);
app.mount('#quickform')